<template>
  <div>
    <p v-if="!surgeryId">
      <br>
      <br>
      <br>
      Please select a location pin on map
    </p>
    <p v-else>
      Surgery Prescription Data @@here
      {{siloId}} {{surgeryId}}
      {{ prescriptionData }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Location-Tab-Content",
  props: [
    'siloId',
    'surgeryId',
    'startDate',
    'endDate',
    'condition',
    'costOrMass'
  ],
  data() {
    return {
      'prescriptionData' : {}
    }
  },
  watch: {
    surgeryId: function () {
      if (this.surgeryId) {
        this.fetchSurgeryPrescriptionData();
      }
    }
  },
  methods: {
    fetchSurgeryPrescriptionData() {
      this.$http.get('prescription/get_silo_condition_aggregation_for_range/')                                                                                          //  let ajaxUrl = `${window.location.protocol}//${window.location.host}/MapView/get_silo_condition_aggregation_for_range/${startDate}-${endDate}/1/0`;
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading Prescription Data :' + data[key].name);
              resultArray.push(data[key]);
            }
            this.prescriptionData = resultArray;
          });
    }
  }
}
</script>

<style scoped>

</style>