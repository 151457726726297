<template>
  <div>
    <div class="p-0 m-0" style="width: 100%;">
      <div class="row" style="background: #295a9f;    font-family: 'Oswald', sans-serif;     color: white;">
        <div class="col-3" align="left">
          <h1 >
            <img src="images/logo/logo-enblem-inverse.png" style="height: 30px; margin-top: -7px; padding-right: 5px"><span class="d-none d-lg-block">Mediview Rx</span>
          </h1>
        </div>
        <div class="col-6 pt-3">
          <span class="h4 mb-2 mr-2">Silo
          <b-form-select class="h5" v-model="siloId" @change="fetchSiloMarkers">
            <option v-for="(silo, idx) in silos_available" :value="silo.id" :key="idx">
              {{ silo.name }}
            </option>
          </b-form-select>
          </span>

        </div>
        <div class="col-2 pt-3 pr-4 text-right">
          <span class="h4 mb-2 ml-1 mr-1 "><b-icon icon="layers"></b-icon>
          <b-form-select class="h5 ml-1" v-model="selected_condition" @change="fetchHeatMap">
            <option v-for="(condition, idx) in conditions_available" :value="condition.id" :key="idx">
              {{ condition.name }}
            </option>
          </b-form-select>
          </span>
        </div>
      </div>
    </div>
    <div ref="map_div">
      <GmapMap
        :zoom="map_zoom"
        :center="map_center"
        :options="options"
        style="width:100%;  height: 600px;"
      >
        <GmapMarker v-for="(item, key) in SurgeryMarkers" :key="key" :position="getPosition(item)"
                     :clickable="true"
                     @click="selectMarker(item);"
                     :draggable="false"
                     :icon="{ url: require('../assets/images/pins/map-gp-pin.png')}"
                     :animation="google.maps.Animation.DROP"
        />
        <GmapInfoWindow
            v-if="selectedLocation !== null"
            :position="{ lat: selectedLocation.lat , lng: selectedLocation.lng }"
            :opened="markerPinInfoBoxOpen"
            @closeclick="markerPinInfoBoxOpen = false;"
        >
          <div class="infoWindow" style="width: 300px;">
            <h4 id="infoWindow-location">{{ selectedLocation.name }}</h4>
          </div>
        </GmapInfoWindow>
        <GmapHeatmapLayer
            :data="heatmapMarkers"
            :options="heatmapOptions"
        />
        <gmap-polygon :paths="paths" />
        <google-kml-layer :url="kml_url" :clickable="true"></google-kml-layer>
      </GmapMap>
    </div>
    <div>
      <div class="pt-3">
        {{heatmapMarkers}}
        <b-tabs content-class="mt-3">
          <b-tab title="location" active>
            <Location-Tab-Content
                :silo-id="siloId"
                :surgery-id="selectedLocation != null ? selectedLocation.id : ''"
                :start-date="startDate"
                :end-date="endDate"
                :condition="selected_condition"
                :cost-or-mass="costOrMass"
            />
          </b-tab>
          <b-tab title="data">
            <Data-Tab-Content
                :silo-id="siloId"
                :surgery-id="selectedLocation != null ? selectedLocation.id : ''"
                :start-date="startDate"
                :end-date="endDate"
                :condition="selected_condition"
                :cost-or-mass="costOrMass"
            />
          </b-tab>
          <b-tab title="settings">
            <Settings-Tab-Content
                :silo-id="siloId"
                :surgery-id="selectedLocation != null ? selectedLocation.id : ''"
                @start-date="startDate"
                @end-date="endDate"
                @condition="selected_condition"
                @cost-or-mass="costOrMass"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!--br><br>
    <button class="btn btn-primary" @click="fetchSiloMarkers">Get Data</button>
    <br><br>
    <ul>
      <li v-for="coordinate in coordinates" :key="coordinate">{{coordinate.lat}},{{coordinate.lng}}</li>
    </ul-->

  </div>
</template>

<script>
import {gmapApi}          from "gmap-vue";
import LocationTabContent from "./Tabs/LocationTabContent.vue";
import SettingsTabContent from "./Tabs/SettingsTabContent.vue";
import DataTabContent     from "./Tabs/DataTabContent.vue";

export default {
  name: "MediviewGoogleMap",
  components: {
    LocationTabContent,
    SettingsTabContent,
    DataTabContent
  },
  data() {
    return {
      map_zoom: 14,                                                                                                      // Show all of uk
      map_center: {lat: 51.4779, lng: -3.2290},                                                                    // Center of UK at Zoom 6
      SurgeryMarkers: [],
      options: {
        mapId: "b3097325e2888890",                                                                                      // Created in google API dashboard
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false
      },
      //mapTypeId: "terrain",
      siloId: 3,                                                                                                       // Default silo on page load
      map_bounds: [[51.4705, -3.26246], [51.4853, -3.19558]],
      silos_available: {},                                                                                              // Loaded by fetchSilos()
      selectedLocation: null,
      markerPinInfoBoxOpen: false,

      startDate: '',
      endDate: '',
      costOrMass: '',

      conditions_available: [ { id: 0, name: 'No overlay'} ],
      selected_condition: 0,
      heatmapMarkers: [],
      heatmapOptions: {maxIntensity: 120, dissipating: false},
      paths: [
        [ {lat: 51.485980, lng: -3.179900}, {lat:51.482238, lng: -3.199997}, {lat: 51.482238, lng: -3.160083}, {lat: 51.498912, lng: -3.202683} ]
      ],
      //kml_url: 'https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml',
      kml_url: 'https://mediview-api.mosaicdigital.tech/kml_data/STP_E54000040_February_2017_Generalised_Clipped_Boundaries_in_England_Bath,_Swindon_and_Wiltshire.kml',
    }
  },
  async mounted() {
    await this.$gmapApiPromiseLazy();
    this.fetchConditions();
    this.fetchSilos();
    this.fetchSiloMarkers();


  },
  computed: {
    google: gmapApi
  },
  methods: {
    fetchKMLBoundary(){
      this.$http.get('conditions')
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading conditions_available :' + data[key].name );
              resultArray.push( data[key] );
            }
            this.conditions_available = resultArray;
          });
    },
    fetchHeatMap(){
      if(this.selected_condition){
      this.heatmapMarkers = [
        {
          location: new this.google.maps.LatLng({ lat: 51.485980, lng: -3.179900 }),
          weight: 100
        },
        {
          location: new this.google.maps.LatLng({ lat: 51.482238, lng: -3.199997 }),
          weight: 50
        },
        {
          location: new this.google.maps.LatLng({ lat: 51.482238, lng: -3.160083 }),
          weight: 80
        }
      ];
      }else {
        this.heatmapMarkers = [];
      }

    },
    fetchTabContent(surgery_id){
      this.location_tab_content = surgery_id + ' mbounds='+ this.map_bounds + ' zoom' + this.map_zoom + ' map_cneter' + this.map_center;

    },
    selectMarker(SurgeryMarkers) {                                                                                    // https://codesandbox.io/s/7mz84k2x1x?file=/src/components/Map.vue
      this.selectedLocation = SurgeryMarkers;
      this.markerPinInfoBoxOpen = true;
      this.fetchTabContent(this.selectedLocation.id);
    },
    fetchConditions(){
      this.$http.get('conditions')
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading conditions_available :' + data[key].name );
              resultArray.push( data[key] );
            }
            this.conditions_available = resultArray;
          });
    },
    fetchSilos(){
      this.$http.get('silos/')
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading Silos :' + data[key].name );
              resultArray.push( data[key] );
            }
            this.silos_available = resultArray;
          });
    },
    fetchSiloMarkers(){
      this.$http.get('silos/'+this.siloId+'/surgeries')
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading Surgeries:' + data[key].postcode );

              resultArray.push( { id: data[key].id,
                                  name: data[key].practice_name,
                                  lng: data[key].lng,
                                  lat: data[key].lat } );
            }

            this.SurgeryMarkers = resultArray;

            this.map_bounds = new this.google.maps.LatLngBounds();   // @@TODO test that map has loaded see mounted hack!
                      console.log( 'Map bounds =- ' + this.map_bounds );
            for (var i = 0; i < this.SurgeryMarkers.length; i++) {
              this.map_bounds.extend(this.SurgeryMarkers[i]);
            }

            this.map_center = this.map_bounds.getCenter();
            this.setBoundsZoomLevel(this.$refs.map_div.clientWidth,this.$refs.map_div.clientHeight);

            this.markerPinInfoBoxOpen = false;
            this.heatmapMarkers = [];
            this.paths = [];
          });
    },
    setBoundsZoomLevel(map_visible_width,map_visible_height){
      let WORLD_DIM = { height: 256, width: 256 };
      let ZOOM_MAX = 21;

      function latRad(lat) {
        let sin = Math.sin(lat * Math.PI / 180);
        let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      let ne = this.map_bounds.getNorthEast();
      let sw = this.map_bounds.getSouthWest();

      let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      let lngDiff = ne.lng() - sw.lng();
      let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;


      let latZoom = zoom(map_visible_height, WORLD_DIM.height, latFraction);
      let lngZoom = zoom(map_visible_width, WORLD_DIM.width, lngFraction);
      
      this.map_zoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
    },
    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng)
      }
    }
  }
};
</script>

<style type="text/css">

::selection { background-color: #E13300; color: white; }
::-moz-selection { background-color: #E13300; color: white; }

a {
  color: #003399;
  background-color: transparent;
  font-weight: normal;
}

h1 {
  color: white;
  background-color: transparent;
  border-bottom: 0px;
  font-size: 42px;
  font-weight: normal;
  margin: 0 0 14px 0;
  padding: 14px 15px 10px 15px;
  margin-block-end: 0px!important;
}

p.footer {
  text-align: right;
  font-size: 11px;
  border-top: 1px solid #D0D0D0;
  line-height: 32px;
  padding: 0 10px 0 10px;
  margin: 20px 0 0 0;
}

</style>