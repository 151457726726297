<template>
  <div id="app">
    <MediviewGoogleMap />
  </div>
</template>

<script>
import MediviewGoogleMap  from "./components/MediviewGoogleMap";

export default {
  name: 'App',
  components: {
    MediviewGoogleMap
  },
  // mounted () {
  //   // At this point, the child GmapMap has been mounted, but its map has not been initialized.
  //   // Therefore we need to write mapRef.$mapPromise.then(() => ...)
  //
  //   this.$refs.mapRef.$mapPromise.then((map) => {
  //     map.panTo({lat: 1.38, lng: 103.80})
  //   })
  // }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>