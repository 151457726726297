<template>
  <div>
    <p v-if="!surgeryId">
      <br>
      <br>
      <br>
      Please select a location pin on map
    </p>
    <div v-else class="fixed-height-content module module-1-2" id="hotbar-controls">
      <div class="prescription-hotbar">
        <div class="form-group inline-block margin-r-10-xs">
          <label>
            <input class="prescription_view" type="radio" name="prescription_view" value="cost"/>
            <span>View by cost</span>
          </label>
        </div>
        <div class="form-group inline-block margin-r-10-xs">
          <label>
            <input class="prescription_view" type="radio" name="prescription_view" value="mass" checked/>
            <span>View by mass</span>
          </label>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="slidecontainer1">Display:</label>
          </div>
          <div id="slidecontainer1" class="slidecontainer data-slider col-md-2">
            <input type="range" min="0" max="1" value="0" class="slider" id="data-type-selector"/>
          </div>
          <div class="col-md-2" id="hotbar-data-type">
          </div>
          <div class="col-md-1"></div>
          <div id="slidecontainer5" class="slidecontainer data-slider col-md-2">
            <input type="range" min="0" max="1" value="0" class="slider" id="display-type-selector"/>
          </div>
          <div class="col-md-2" id="hotbar-display-type">
          </div>
        </div>
      </div>
      <div class="prescription-hotbar">
        <div class="row">
          <div class="col-md-12">
            <label for="slidecontainer2">From:</label>
          </div>
          <div id="slidecontainer2" class="slidecontainer data-slider col-md-6">
            <input type="range" min="0" max="100" value="0" class="slider" id="start-date-selector">
          </div>
          <div class="col-md-6" id="hotbar-start-date">
          </div>
        </div>
      </div>
      <div class="prescription-hotbar">
        <div class="row">
          <div class="col-md-12">
            <label for="slidecontainer3">To:</label>
          </div>
          <div id="slidecontainer3" class="slidecontainer data-slider col-md-6">
            <input type="range" min="1" max="24" value="1" class="slider" id="end-date-selector">
          </div>
          <div class="col-md-6" id="hotbar-end-date">
          </div>
        </div>
      </div>
      <div class="prescription-hotbar">
        <div class="row">
          <div class="col-md-12">
            <label for="slidecontainer4">Condition:</label>
          </div>
          <div id="slidecontainer4" class="slidecontainer data-slider col-md-6">
            <select id="condition-selector">
<!--
              for (let key in conditions) {
              html += `<option value="${key}">${conditions[key]}</option>`
              }-->
                <option value="1">1</option>
              </select>
          </div>
          <div class="col-md-6" id="hotbar-condition">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Settings-Tab-Content",
  components: {  },
  props: [
    'siloId',
    'surgeryId',
    'startDate',
    'endDate',
    'condition',
    'costOrMass'
  ],
  data() {
    return {
      'settingsData' : {},
      checked: true,
    }
  },
  watch: {
    surgeryId: function () {
      if (this.surgeryId) {
        this.fetchSettingsControlsData();
      }
    }
  },
  methods: {
    toggle_costOrMass(){
      (this.costOrMass === 'mass') ? this.costOrMass = 'cost' : this.costOrMass = 'mass';
    },
    setContentDataHotbars(){
      /*
        let surgery = surgeries[key];
        html += `<option value="${surgery.nhsCode}"`
        if ($('.column-map-activity-slider').attr('id') == surgery.nhsCode) {
          html += ` selected="selected"`;
        }
        html += ` >${surgery.surgeryName}</option>`;
      }

      for (let key in conditions) {
        html += `<option value="${key}">${conditions[key]}</option>`
      }*/
    },
    fetchSettingsControlsData() {
     /* this.$http.get('prescription/get_silo_condition_aggregation_for_range/')                                                                                          //  let ajaxUrl = `${window.location.protocol}//${window.location.host}/MapView/get_silo_condition_aggregation_for_range/${startDate}-${endDate}/1/0`;
          .then(response => {
            return response.json();
          })
          .then(data => {
            const resultArray = [];
            for (let key in data) {
              console.log('Loading Prescription Data :' + data[key].name);
              resultArray.push(data[key]);
            }
            this.prescriptionData = resultArray;
          });*/
    }
  }
}
</script>

<style scoped>

</style>