import Vue from 'vue'
import VueResource from 'vue-resource'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//import * as VueGoogleMaps from "vue2-google-maps" // Import package
import * as GmapVue from 'gmap-vue'

Vue.component('google-kml-layer', GmapVue.KmlLayer);

Vue.config.productionTip = false

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyBuZG8QnItRMSpVlZkemQf7xtKqEC4_SWM",
    libraries: 'places,drawing,visualization'
  },
  installComponents: true
});

Vue.use(VueResource);

Vue.http.options.root = 'https://mediview-api.mosaicdigital.tech/';
//Vue.http.options.root = 'http://mediview-api/';
//Vue.http.headers.common['Authorization'] = 'Basic YXBpOnBhc3N3b3Jk'+mediview_auth_token;

new Vue({
  render: h => h(App),
}).$mount('#app')
